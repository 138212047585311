<template>
  <div id="myaccount">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>My Account</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <!--<a href="index-2.html">Home </a>--><router-link
                    to="/"
                    style="color: white"
                    >Home</router-link
                  >
                </li>
                <li>My Account</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-details-wrap ptb-100">
      <div class="container">
        <div class="row gx-5">
          <div
            class="col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2"
          >
            <div class="sidebar">
              <Leftsidebar></Leftsidebar>
            </div>
          </div>
          <div
            class="col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1"
          >
            <h4 class="border-bottom pb-4">
              <i class="fa fa-user" aria-hidden="true"></i>
              My Account
            </h4>
            <div class="card mb-4">
              <div class="card-body text-center">
                <img
                  :src="useravatar"
                  alt="avatar"
                  class="rounded-circle img-fluid"
                  style="width: 150px"
                />
                <h5 class="my-3 profile">{{ user ? user.name : "" }}</h5>
                <p class="text-muted mb-2">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  {{ user.default_address ? user.default_address.address : "" }}
                </p>
                <p class="text-muted mb-2">
                  <a href="#"
                    ><i class="fa fa-phone" aria-hidden="true"></i>
                    {{ user ? user.phone : "" }}</a
                  >
                </p>
                <p class="text-muted mb-4">
                  <a href="#"
                    ><i class="fa fa-envelope-o" aria-hidden="true"></i>
                    {{ user ? user.email : "" }}</a
                  >
                </p>
                <div class="row">
                  <!-- <div class="col-md-6 text-center" style="margin: auto">
                    <div class="d-flex justify-content-center mb-2">
                      <router-link
                        to="/updateprofile"
                        class="btn btn-primary w-100"
                        ><span
                          ><i class="fa fa-pencil" aria-hidden="true"></i>
                          Update</span
                        ></router-link
                      >
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6">
                    <div class="d-flex justify-content-center mb-2">
                      <router-link to="/login" class="btn btn-danger w-100"
                        ><span
                          ><i class="fa fa-pencil" aria-hidden="true"></i>
                          Password Changes</span
                        ></router-link
                      >
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import Leftsidebar from "@/views/sidebar/Leftsidebar.vue";
import { getLocalStorage } from "@/store/service";
export default {
  name: "myaccount",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      useravatar: require("@/assets/img/user-avatar.jpg"),
      user: "",
    };
  },
  components: {
    Header,
    Footer,
    Leftsidebar,
  },
  mounted() {
    this.userData();
  },
  methods: {
    userData() {
      this.user = getLocalStorage("userData");
      if (this.user.role == "table") {
        this.$router.push("/myorders");
      }
    },
  },
};
</script>
